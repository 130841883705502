html,
body,
body > #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  overflow: hidden;
}

.application-header, .application-content {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.application-header .MuiFormControl-marginDense, 
.application-content .MuiFormControl-marginDense {
  margin-top: 6px;
  margin-bottom: 3px;
  margin-top: 3px!important;
  margin-bottom: 12px!important;
}

.application-content .fieldDisplay {
  width: 100%;
  min-height: 55px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.application-content .fieldDisplay > * {
  width: calc(100% - 16px) !important;
}

.application-header .fieldDisplay > div > div > div > input, 
.application-content .fieldDisplay > div > div > div > input {
  width: 100% !important;
}

.application-header .MuiTableCell-sizeSmall, 
.application-content .MuiTableCell-sizeSmall {
  padding: 3px 16px 3px 0 !important;
}

.application-header .MuiTableCell-sizeSmall:last-child, 
.application-content .MuiTableCell-sizeSmall:last-child {
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Show ellipsis in long TextField labels instead of overflowing */
.MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* 133% is effectively 100% due to transform: scale(0.75) */
  max-width: calc(133.333333% - 26px);
}

/* allows for multiple loan product summary data tables to remain draggable at once as well as allowing for interaction with elements under the draggable element */
.react-draggable[role="presentation"] {
  max-width: 1px !important;
  max-height: 1px !important;
  margin-left: -480px !important;
}

.react-draggable[role="presentation"] * {
  overflow: visible !important;
}

.react-date-picker__calendar {
  width: 320px !important;
  margin-left: -8px;
  z-index: 2 !important;
}

.react-date-picker {
  width: 100%;
}

.react-date-picker input {
  width: 100%;
  background: none !important;
}

.react-date-picker input:focus {
  outline-color: rgba(63, 81, 181, 1);
  background: none;
}

.react-date-picker__lp-wrapper {
  box-sizing: border-box;
  padding: 8.5px 14px;
  margin: 4px 0;
}

.react-date-picker__lp-wrapper fieldset {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: -5px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  margin: 0px;
}

.react-date-picker__lp-wrapper fieldset legend {
  color: rgba(0, 0, 0, 0.46);
  font-size: 12px;
}

.react-date-picker:focus {
  background-color: red;
}

.react-date-picker__wrapper {
  border: none !important;
}

.only-for-print {
  display: none;
}

.product-specs .fieldDisplay {
  margin: 8px;
}

/* BEGIN - print styles tailored to exporting pricing scenarios to pdf */
@media print {
  .application-header,
  .page-loan-pricing-product-detail-action-panel,
  .page-component-fields-column-welcome,
  .page-loan-pricing-component-product-results {
    display: none !important;
  }

  .only-for-print {
    display: block !important;
  }  

  .hide-for-print {
    display: none !important;
  }  

  * {
    overflow: visible !important;
  }

  .key-value {
    width: 48% !important;
    max-width: 48% !important;
    min-width: 48% !important;
  }

  .molecule-accordion > div {
    padding: 0 16px !important;
    height: auto !important;
  }  

  .page-loan-pricing aside {
    flex: 0 1 35% !important;
    min-width: 35% !important;
  }

  .hide-from-print {
    display: none !important;
  }

  .MuiDialog-root[role="presentation"] {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
  }

  .MuiDialog-root[role="presentation"] .MuiDialog-paperFullWidth {
    width: 100% !important;
  }

  .MuiDialog-root[role="presentation"] .MuiDialog-paper {
    margin: 0 !important;
  }

  .MuiDialog-root[role="presentation"] .MuiDialog-paperWidthMd {
    max-width: 100% !important;
    width: 100% !important;
  }

  .MuiDialog-root[role="presentation"] .MuiDialog-paperScrollPaper {
    max-height: auto !important;
  }

  body {
    overflow: visible !important;
  }

  #root {
    height: auto !important;
  }

  .MuiAppBar-root,
  .primary-nav,
  .table-actions {
    display: none !important;
  }
}

/* END - print styles tailored to exporting pricing scenarios to pdf */

@media (max-width: 850px) {
  .hide-for-mobile {
    display: none !important;
  }
}

@media (min-width: 851px) {
  .hide-for-desktop {
    display: none !important;
  }
}